@charset "utf-8";
@import "../../node_modules/bulma/sass/utilities/initial-variables";

// Set your brand colors
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// Update Bulma's global variables
// $primary: $purple;
// $link: $pink;
// $info: $pink;

// padding on bottom is 0 since we have a footer
$section-padding: 5rem 1.5rem 0rem 1.5rem;
@import "../../node_modules/bulma/bulma";
