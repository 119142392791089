.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.pageWrapper {
  position: relative;
  /* min Height is not 100 since we have padding */
  min-height: 80vh;
}

.customFooter {
  position: absolute;
  bottom: -8rem;
  width: 100%;
  border-top: 1px solid #d3d3d3;
  margin-bottom: -1rem;
  padding-bottom: 2rem;
}

.oerebroLogo {
  padding-top: 25px;
  height: 5rem;
}
